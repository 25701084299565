<!--套组赠送雪活泉选项-->
<template>
  <div class="SetGiveSnowOptions app-container" v-if="optionsShow">
    <p :class="giveNumberTotal===selectedNumber ? 'pGreen' : 'pRED'">
      可选数量:{{ giveNumberTotal }} 已选数量: {{ selectedNumber }}
    </p>
    <el-table
        v-loading="loading"
        :data="options"
        fit
        border
        stripe
        highlight-current-row
    >
      <el-table-column label="商品编码" prop="code" width="140" align="center"/>
      <el-table-column label="商品名称" prop="name" show-overflow-tooltip align="center"/>
      <el-table-column label="购买数量" prop="quantity" width="120" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              @change="handleQuantityChange"
              style="width:100px;"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: 'SetGiveSnowOptions',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      default: () => []
    },
    loading: Boolean
  },
  data() {
    return {
      optionsShow: false,
      options: [
        {
          code: 'CN-C001-01-00',
          name: '雪活泉丽妍凝胶',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          pv: 0,
          isGive: 1,
          id: 0
        },
        {
          code: 'CN-C002-01-00',
          name: '雪活泉温舒凝胶',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          pv: 0,
          isGive: 1,
          id: 0
        },
        {
          code: 'CN-C003-01-00',
          name: '雪活泉紧致凝胶',
          quantity: 0,
          point: 0,
          price: 0,
          proPrice: 0,
          pv: 0,
          isGive: 1,
          id: 0
        },
      ],
      setGiveNumberObj: {
        'CN-N000111-24': 15, // 升级白金套3.0
        'CN-N000110-24': 15, // 气血温通养生仪3.0白金套组
        'CN-N000109-24': 15, // 气血温通养生仪3.0套组
        'CN-N000112-24': 10, // 气血温通养生仪3.0套组（N)
      },
    }
  },
  computed: {
    giveNumberTotal() {
      if (this.data.length===0) {
        return 0
      }
      console.log(this.data)
      let total = 0
      this.data.forEach(item => {
        if (item && item.code && typeof item.quantity === 'number') {
          const giveNumber = this.setGiveNumberObj[item.code];
          if (typeof giveNumber === 'number') {
            total += giveNumber * item.quantity;
          }
        }
      })
      return total
    },
    selectedNumber() {
      return this.options.reduce((total, item) => {
        const quantity = Number(item.quantity); // 将 quantity 转换为数字
        if (isNaN(quantity)) {
          console.warn(`无效的 quantity 值: ${item.quantity}`);
          return total; // 如果值无效，跳过累加
        }
        return total + quantity;
      }, 0);
    }
  },
  watch: {
    giveNumberTotal(newValue) {
      this.optionsShow = newValue > 0
      this.$emit('input', {
        numIsSame: this.giveNumberTotal === this.selectedNumber,
        isAttend: this.optionsShow,
        options: this.options
      })
    }
  },
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    handleQuantityChange() {
      this.$emit('input', {
        numIsSame: this.giveNumberTotal === this.selectedNumber,
        isAttend: this.optionsShow,
        options: this.options
      })
    },
  }
}

</script>

<style lang="scss" scoped>
.pRED {
  color: red;
}
.pGreen {
  color: green;
}
</style>
