<template>
  <div>
    <app-breadcrumb />
    <div class="page-container order-wrapper">
      <el-form ref="orderForm" :model="order" :rules="rules" label-width="140px" size="small">
        <div class="step1" v-if="activeIndex===0">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info" style="margin-bottom:10px;" />
          <el-form-item label="订货人编号:" prop="memberNo">
            <el-input style="max-width: 200px" v-model.trim="order.memberNo" clearable @blur.native.capture="handleNoCheck(order.memberNo)" />
            <span class="redspan">&nbsp;&nbsp;&nbsp;&nbsp;{{ memberNo }}</span>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onNext">下一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step2" v-show="activeIndex===1">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <div class="pane-item">顾客编号: {{ order.memberNo }}</div>
          <p></p>
          <div class="pane-title">商品列表</div>
          <el-divider></el-divider>
          <div class="summary">
            <span>金额:<em>{{ amount }}</em></span>
            <span>消费指数:<em>{{ pv }}</em></span>
          </div>
          <div v-show="open">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="请选择旋磁智感健康文胸产品尺码" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01057</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸70A/B</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details1.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01058</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸70C/D</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details2.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01059</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸75A/B</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details3.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01060</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸75C/D</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details4.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01061</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸80A/B</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details5.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01062</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸80C/D</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details6.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01063</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸85A/B</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details7.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01064</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸85C/D</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details8.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </el-tabs>
            <el-tabs v-model="activeName" >
              <el-tab-pane label="请选择远红外塑体衣产品尺码" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01051</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)M</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details9.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01052</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)L</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details10.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01053</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XL</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details11.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01054</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XXl</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details12.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01055</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XXXL</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details13.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </el-tabs>
          </div>
          <div v-show="opens">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="请选择远健康磁性护腰产品尺码" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-02</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(M)</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details14.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-03</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(L)</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details15.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-04</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(XL)</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details16.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-05</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(XXL)</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details17.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </el-tabs>
          </div>
          <div v-show="openss">
            <el-tabs v-model="activeName" v-if="this.nowTime < 1643731199000">
              <el-tab-pane label="(促销期间购买20000PV气血温通养生仪成为白金卡会员，赠送以下三个套餐任选其一)" name="first" />
              <el-radio style="margin: 10px 0px" v-model="radio1" label="1">A套餐：赠送A积分13000</el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio1" label="2">B套餐:睡眠凝胶套组1.5米版（内含健康磁性多功能床垫1张、负离子远红外子母被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。</el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio1" label="3">C套餐:睡眠凝胶套组1.8米版（内含健康磁性多功能床垫1张、负离子远红外子母被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。</el-radio>
            </el-tabs>
            <el-tabs v-model="activeName" v-if="(1643731200000 < this.nowTime)">
              <el-tab-pane label="(促销期间购买20000PV气血温通养生仪成为白金卡会员，赠送以下三个套餐任选其一)" name="first" />
              <el-radio style="margin: 10px 0px" v-model="radio1" label="1">A套餐：赠送A积分10000</el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio1" label="2">B套餐:睡眠凝胶套组1.5米版（内含健康磁性多功能床垫1张、负离子远红外子母被1张），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。</el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio1" label="3">C套餐:睡眠凝胶套组1.8米版（内含健康磁性多功能床垫1张、负离子远红外子母被1张），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。</el-radio>
            </el-tabs>
          </div>
          <div v-show="opensss">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="(促销期间购买20000PV气血温通养生仪成为白金卡会员，赠送以下三个套餐任选其一)" name="first" />
              <el-radio style="margin: 10px 0px" v-model="radio" label="1">A套餐：赠送A积分13000</el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio" label="2">B套餐:睡眠凝胶套组1.5米版（内含健康磁性多功能床垫1张、负离子远红被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。</el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio" label="3">C套餐:睡眠凝胶套组1.8米版（内含健康磁性多功能床垫1张、负离子远红被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。</el-radio>
            </el-tabs>
          </div>
          <div v-show="open1">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="(购买睡眠凝胶套组，请选择对应床垫尺码)" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="18"><div class="grid-content bg-purple">健康磁性多功能床垫1张(1.8米)、负离子远红被一张、健康磁性枕2个</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details24.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="18"><div class="grid-content bg-purple">健康磁性多功能床垫1张(1.5米)、负离子远红被一张、健康磁性枕2个</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details25.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </el-tabs>
          </div>
          <div v-show="openTZ20074">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="(购买睡眠凝胶套组，请选择对应床垫尺码)" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="18"><div class="grid-content bg-purple">CN-T009-01-00-健康磁性多功能床垫</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="detailsTZ200741.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="18"><div class="grid-content bg-purple">CN-T007-01-00-健康磁性多功能床垫(小)</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="detailsTZ200742.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </el-tabs>
          </div>
          <div v-show="open2">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="(购买0508纳米微雕3.0套组，请选择对应尺码)" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01024</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">魅力红围巾</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details26.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T015-01-30</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">天空蓝围巾</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details27.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T015-01-40</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">高级灰围巾</div></el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details28.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </el-tabs>
          </div>
<!--         气血温通养生仪QC（CN）03025  CN-M006-01-30 -->
          <div v-show="open5" >
            <el-tabs v-model="activeName" v-if="this.nowTime < 1643731199000">
              <el-tab-pane label="(促销期间购买气血温通养生仪CN-M006-01-30（消费指数20000）成为白金级优惠顾客，赠送以下三个套餐任选其一)" name="first" />
              <el-radio style="margin: 10px 0px" v-model="radio4" label="1">A套餐: 赠送购买仪器人员睡眠凝胶套组1.5米版(内含健康磁性多功能床垫1张、负离子远红外子母被1张、健康磁性枕2个)，
                <br/>以及温舒凝胶、紧致凝胶、丽妍凝胶各5支</el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio4" label="2">B套餐: 赠送购买仪器人员睡眠凝胶套组1.8米版(内含健康磁性多功能床垫1张、负离子远红外子母被1张、健康磁性枕2个)，
                <br/>以及温舒凝胶、紧致凝胶、丽妍凝胶各5支</el-radio>
              <el-radio v-show="this.open030251" style="margin: 10px 0px" v-model="radio4" label="3">C套餐: 赠送舒络仪套餐一套(内含便携舒络仪3.0 1台(CN-M002-01-00 +
                6支雪活泉舒怡按摩霜(中文)(CN-C029-01-00)<br/>+6支温舒凝胶(HZ(CN)02001)+8000积分)</el-radio>
              <el-radio v-show="this.open030252" style="margin: 10px 0px" v-model="radio4" label="3">C套餐: 赠送舒络仪套餐一套(内含便携舒络仪3.0 1台(CN-M001-01-0+
                6支雪活泉舒怡按摩霜(中文)(CN-C029-01-00)<br/>+6支温舒凝胶(HZ(CN)02001)+8000积分)</el-radio>
              </el-tabs>
            <el-tabs v-model="activeName" v-if="(1643731200000 < this.nowTime)">

              <el-tab-pane label="(促销期间购买气血温通养生仪CN-M006-01-30（消费指数20000）成为白金级优惠顾客，赠送以下三个套餐任选其一)" name="first" />
              <el-radio style="margin: 10px 0px" v-model="radio4" label="1">A套餐:健康磁性多功能床垫（小）（1个）+ 负离子远红外子母被（1个）+ 健康磁性枕（2个）
              </el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio4" label="2">B套餐: 健康磁性多功能床垫（1个）+ 负离子远红外子母被（1个）+ 健康磁性枕（2个）
              </el-radio>
              <el-radio style="margin: 10px 0px" v-model="radio4" label="3">C套餐: 赠送舒络仪套餐一套(内含便携舒络仪3.0 1台(CN-M001-01-0+
                6支雪活泉舒怡按摩霜(中文)(CN-C029-01-00)<br/>+6支温舒凝胶(HZ(CN)02001)+4800积分)</el-radio>
            </el-tabs>
          </div>
<!--          TZ(CN)20075时光宝盒白金套组-->
          <div v-show="TZCN20075">
            <el-table
                border
                :data="TZCN20075list"
                header-align="center"
                style="width: 100%">
              <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
              <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
              <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
              <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
              <el-table-column label="购买数量" prop="quantity" width="200" align="center">
                <template slot-scope="{row}">
                  <el-input-number
                      v-model="row.quantity"
                      size="mini"
                      :min="0"
                      style="width:100px;"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div><!--          TZ(CN)20075时光宝盒白金套组-->
          <div v-show="TZCN20076">
            <el-table
                border
                :data="TZCN20076list"
                header-align="center"
                style="width: 100%">
              <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
              <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
              <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
              <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
              <el-table-column label="购买数量" prop="quantity" width="200" align="center">
                <template slot-scope="{row}">
                  <el-input-number
                      v-model="row.quantity"
                      size="mini"
                      :min="0"
                      style="width:100px;"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
          <product-selector v-if="activeIndex===1" v-model="order.details" :data="goods" :loading="goodsLoading" />
          <SetGiveSnowOptions v-model="zengPin" :data="order.details" :loading="goodsLoading"/>
<!--         TZ(CN)20033促销 -->
          <div v-show="TZ20033" style="padding: 20px">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="(促销期间购买购买合家欢套组（TZ(CN)20033），请选择套餐)" name="first" />
              <el-row>
                <el-col :span="8">CN-T015-01-40</el-col>
                <el-col :span="12">能量围巾（高级灰）</el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details36.quantity" size="mini" @change="handleChange" :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row>
                <el-col :span="8">QC(CN)01033</el-col>
                <el-col :span="12">能量围巾（活力橙）</el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details37.quantity" size="mini" @change="handleChange" :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row>
                <el-col :span="8">CN-T015-01-30</el-col>
                <el-col :span="12">能量围巾（天空蓝）</el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details38.quantity" size="mini" @change="handleChange" :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
              <el-row>
                <el-col :span="8">QC(CN)01024</el-col>
                <el-col :span="12">能量围巾（魅力红）</el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details39.quantity" size="mini" @change="handleChange" :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </el-tabs>
          </div>
<!--          TZCN19508-->
          <TZCN19508
              v-show="TZCN19508_display"
              :TZCN19508_num="TZCN19508_num"
          @hyTypeChange="hyTypeChange"
          @hxTypeChange="hxTypeChange"
          ></TZCN19508>
<!--          CN-N000039-24 套组 选护膝-->
          <CNN00003924
          v-show="CN24_display"
          :CN24_num="CN24_num"
          @CN_hxTypeChange="CN_hxTypeChange"
          ></CNN00003924>
<!--        TZ(CN)19511 CN-N000024-24 套组 床垫选尺码  -->
          <MattressSizeSelection
              :Mattress_num="Mattress_num"
              :Mattress_num1="Mattress_num1"
              @CDChange1="CDChange1"
              @CDChange="CDChange"
          ></MattressSizeSelection>
          <PK02624
              v-show="PK02624_display"
              :PK02624_num="PK02624_num"
              @PK_HX_Change="PK_HX_Change"
              @PK_HY_Change="PK_HY_Change"
              @PK_JY_Change="PK_JY_Change"
              @PK_DD_Change="PK_DD_Change"
          ></PK02624>
<!--          CN-N000102-24  -->
          <CNN00010224
              v-if="CNN00010224_num > 0"
              :CNN00010224_num="CNN00010224_num"
              @CNN00010224_HY_Change="CNN00010224_HY_Change"
              @CNN00010224_HX_Change='CNN00010224_HX_Change'
          ></CNN00010224>
<!--          CN-M006-01-20养生仪-->
          <CNM0060120
              v-if="CNM0060120num>=1 && this.CNM0060120time"
              :CNM0060120num="CNM0060120num"
              @getYSYidChange="getYSYidChange"
          ></CNM0060120>
          <CNN00001224
              v-if="CNN00001224num>=1"
              :CNN00001224num="CNN00001224num"
              @getKFYChange="getKFYChange"
          ></CNN00001224>
<!--          CN-N000109-24-->
          <CNN00010924
              v-if="CNN00010924num>=1"
              :CNN00010924num="CNN00010924num"
              @get_CNN00010924_Change="get_CNN00010924_Change"
          ></CNN00010924>
<!--          CN-N000110-24-->
          <CNN00011024
              v-if="CNN00011024num>=1"
              :CNN00011024num="CNN00011024num"
              @CNN00011024_HX_Change="CNN00011024_HX_Change"
              @CNN00011024_HY_Change="CNN00011024_HY_Change"
              @CNN00011024_WJ_Change="CNN00011024_WJ_Change"
          ></CNN00011024>
          <!--          CN-N000111-24-->
          <CNN00011124
              v-if="CNN00011124num>=1"
              :CNN00011124num="CNN00011124num"
              @CNN00011124_WJ_Change="CNN00011124_WJ_Change"
          ></CNN00011124>
<!--          CN-N000117-24-->
          <CNN00011724
              v-if="CNN00011724num>=1"
              :CNN00011724num="CNN00011724num"
              @CNN00011724_CD_Change="CNN00011724_CD_Change"
          ></CNN00011724>
<!--          CN-N000118-24-->
          <CNN00011824
              v-if="CNN00011824num>=1"
              :CNN00011824num="CNN00011824num"
              @CNN00011824_HX_Change="CNN00011824_HX_Change"
              @CNN00011824_HY_Change="CNN00011824_HY_Change"
          ></CNN00011824>
<!--          9.15-10.5-->
          <el-form-item class="btns">
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onConfirm">下一步</el-button>
            <el-button v-if="isAgent" icon="el-icon-back" @click="onForward">上一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step3" v-if="activeIndex===2">
          <div class="pane-title">支付信息</div>
          <el-divider></el-divider>
          <el-form-item label="商品总金额:" style="margin-bottom:0;"><span>{{ amount }}</span></el-form-item>
          <el-form-item label="应支付金额:" style="margin-bottom:0;"><span class="amt">{{ amount }}</span></el-form-item>
          <el-form-item v-if="this.Fc > 0" label="是否使用赠送账户:" style="margin-bottom:0;">
            <el-select size="mini" v-model="isFc" placeholder="请选择">
              <el-option label="是" value="1"/>
              <el-option label="否" value="0"/>
            </el-select>
          </el-form-item>
          <el-form-item label="支付密码:" prop="password">
            <el-input v-model.trim="order.password" show-password placeholder="请填写您的支付密码" style="width:280px;"/>
          </el-form-item>
          <div class="pane-title">订单明细</div>
          <el-table
            :data="order.details"
            show-summary
            border
            fit
            stripe
            :summary-method="orderDetailSummaries"
            style="width:100%;margin:10px 0 20px 0;"
          >
            <el-table-column label="商品编码" prop="code" width="140" align="center" />
            <el-table-column label="商品名称" prop="name" header-align="center" show-overflow-tooltip />
            <el-table-column label="商品价格" prop="price" width="80" align="center"/>
            <el-table-column label="消费指数" prop="pv" width="80" align="center" />
            <el-table-column label="购买数量" prop="quantity" width="80" align="center"/>
            <el-table-column label="金额合计" width="100" align="center">
              <template slot-scope="{row}">
                <span>{{ (row.price * row.quantity).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="消费指数合计" width="110" align="center">
              <template slot-scope="{row}">
                <span>{{ (row.pv * row.quantity).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否赠品" width="80" align="center">
              <template slot-scope="{row}">
                <div v-if="row.gift === '是' || row.isGive === 1">是</div>
                <div v-else-if="row.gift === '否' ">否</div>
                <div v-else>套组内产品</div>
              </template>
            </el-table-column>
          </el-table>
          <el-form-item class="btns">
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onSubmit">保存</el-button>
            <el-button icon="el-icon-back" @click="onForward2">上一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step4" v-if="activeIndex===3">
          <div class="success">
            <img :src="require('@/assets/images/success.png')">
            <div class="success-txt">恭喜您，创建订单成功。</div>
            <div class="success-txt">订单号：<span>{{ orderNo }}</span></div>
            <div>
              <el-button icon="el-icon-s-home" @click="onBackHome">订单查询</el-button>
              <el-button icon="el-icon-refresh" @click="onContinue('orderForm')">继续订货</el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductSelector from '@/components/ProductSelector'
import TZCN19508 from '@/components/promotion/TZCN19508.vue'
import CNN00003924 from '@/components/promotion/CN-N000039-24.vue'
import MattressSizeSelection from '@/components/promotion/MattressSizeSelection.vue'
import PK02624 from '@/components/promotion/PK02624.vue'
import CNM0060120 from '@/components/promotion/CN-M006-01-20.vue'
import CNN00001224 from '@/components/promotion/CN-N000012-24.vue'
import CNN00010924 from '@/components/promotion/CN-N000109-24.vue'
import CNN00011024 from '@/components/promotion/CN-N000110-24.vue'
import CNN00011124 from '@/components/promotion/CN-N000111-24.vue'
import CNN00010224 from '@/components/promotion/CN-N000102-24.vue'
import {validateMember, fetchGoods, createOrder, goodsAgent, fcBalance, CNAugustPromotionios} from '@/api/order/uplevel'
import { searchMember } from '@/api/agent'
import CNN00011724 from "@/components/promotion/CN-N000117-24.vue";
import CNN00011824 from "@/components/promotion/CN-N000118-24.vue";
import SetGiveSnowOptions from "@/components/promotion/SetGiveSnowOptions.vue";
// import { isPaymentPassword } from '@/api/home'
export default {
  name: 'OrderUpLevel',
  components: {
    CNN00011724,
    ProductSelector,
    TZCN19508,
    MattressSizeSelection,
    PK02624,
    CNN00003924,
    CNM0060120,
    CNN00001224,
    CNN00010224,
    CNN00010924,
    CNN00011024,
    CNN00011124,
    CNN00011824,
    SetGiveSnowOptions
  },
  data() {
    return {
      zengPin: null,
      // CN-N000102-24
      CNN00010224_num:0,
      CNN00010224_HX_list:[],
      CNN00010224_HY_list:[],
      // CN-N0000-12-24
      CNN00001224num:0,
      CNN00001224id:0,
      // CN-N000109-24
      CNN00010924num:0,
      CNN00010924id:0,
      // CN-M006-01-20养生仪
      CNM0060120num:0,
      CNM0060120time:false,
      CNM0060120id:'',
      // CN-N000110-24
      CNN00011024num:0,
      CNN00011024_HX_list:[],
      CNN00011024_HY_list:[],
      CNN00011024_WJ_list:[],
      CNN00011024_HX_num:0,
      CNN00011024_HY_num:0,
      CNN00011024_WJ_num:0,
      // CN-N000111-24
      CNN00011124num:0,
      CNN00011124_WJ_list:[],
      CNN00011124_WJ_num:0,
      // CN-N000117-24
      CNN00011724num:0,
      CNN00011724_CD_id:'',
      // CN-N000118-24
      CNN00011824num:0,
      CNN00011824_HX_list:[],
      CNN00011824_HY_list:[],
      CNN00011824_HX_num:0,
      CNN00011824_HY_num:0,
      // 赠送账户
      Fc:0,
      isFc:'0',
      // CN-N000039-24套组
      CN24_display:false,
      CN24_num:0,
      CN24_HX_list:[],
      CN24_HX_num:0,
      // PK02624套组
      PK02624_display:false,
      PK02624_num:0,
      PK02624_HX_num:0,
      PK02624_HX_list:[],
      PK02624_HY_num:0,
      PK02624_HY_list:[],
      PK02624_JY_num:0,
      PK02624_JY_list:[],
      PK02624_DD_num:0,
      PK02624_DD_list:[],
      // TZ(CN)19511 CN-N000024-24 套组 床垫选尺码
      Mattress_num:0,
      Mattress_num1:0,
      Mattress_num_511:0,
      Mattress_num_514:0,
      Mattress_size_num:0,
      Mattress_size_num1:0,
      Mattress_display:false,
      Mattress_display1:false,
      Mattress_list:[],
      Mattress_list1:[],
      // CN-N000019-24 套组选尺码
      TZCN19508_num:0,
      TZCN19508_display:false,
      HuYao_19508_list:[],
      HuYao_19508_num:0,
      HuXi_19508_num:0,
      HuXi_19508_list:[],
      amountofmoney:null,
      radio:'0',
      radio1:'0',
      memberNo:'',
      activeIndex: 0,
      loading: false,
      order: {
        memberNo: undefined,
        password: undefined,
        details: [],
      },
      nowTime:'',
      ids:0,
      ids1:0,
      activeName:'first',
      //女神套组
      open:false,
      details1:{
        name:'旋磁智感健康文胸70A/B',
        code:'QC(CN)01057',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-1
      },
      details2:{
        name:'旋磁智感健康文胸70C/D',
        code:'QC(CN)01058',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-2
      },
      details3:{
        name:'旋磁智感健康文胸75A/B',
        code:'QC(CN)01059',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-3
      },
      details4:{
        name:'旋磁智感健康文胸75C/D',
        code:'QC(CN)01060',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-4
      },
      details5:{
        name:'旋磁智感健康文胸80A/B',
        code:'QC(CN)01061',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-5
      },
      details6:{
        name:'旋磁智感健康文胸80C/D',
        code:'QC(CN)01062',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-6
      },
      details7:{
        name:'旋磁智感健康文胸85A/B',
        code:'QC(CN)01063',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-7
      },
      details8:{
        name:'旋磁智感健康文胸85C/D',
        code:'QC(CN)01064',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-8
      },
      details9:{
        name:'远红外塑体衣(平角款)M',
        code:'QC(CN)01051',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-9
      },
      details10:{
        name:'远红外塑体衣(平角款)L',
        code:'QC(CN)01052',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-10
      },
      details11:{
        name:'远红外塑体衣(平角款)XL',
        code:'QC(CN)01053',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-11
      },
      details12:{
        name:'远红外塑体衣(平角款)XXL',
        code:'QC(CN)01054',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-12
      },
      details13:{
        name:'远红外塑体衣(平角款)XXXL',
        code:'QC(CN)01055',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-13
      },
      //康丽舒
      opens:false,
      details14:{
        name:'健康磁性护腰2.0(M)',
        code:'CN-T003-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-14
      },
      details15:{
        name:'健康磁性护腰2.0(L)',
        code:'CN-T003-01-03',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-15
      },
      details16:{
        name:'健康磁性护腰2.0(XL)',
        code:'CN-T003-01-04',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-16
      },
      details17:{
        name:'健康磁性护腰2.0(XXL)',
        code:'CN-T003-01-05',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-17
      },
      //养生仪
      openss:false,
      opensss:false,
      details18:{
        name:'A套餐：赠送A积分13000',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-18
      },
      details19:{
        name:'B套餐:睡眠凝胶套组1.5米版（内含健康磁性多功能床垫1张、负离子远红被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-19
      },
      details20:{
        name:'C套餐:睡眠凝胶套组1.8米版（内含健康磁性多功能床垫1张、负离子远红被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-20
      },
      details21:{
        name:'A套餐：赠送A积分13000',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-18
      },
      details22:{
        name:'B套餐:睡眠凝胶套组1.5米版（内含健康磁性多功能床垫1张、负离子远红被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-19
      },
      details23:{
        name:'C套餐:睡眠凝胶套组1.8米版（内含健康磁性多功能床垫1张、负离子远红被1张、健康磁性枕2个），以及温舒凝胶、紧致凝胶、丽妍凝胶各5支。',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-20
      },
      //睡眠套组
      open1:false,
      details24:{
        name:'健康磁性多功能床垫1张(1.8米)、负离子远红被一张、健康磁性枕2个',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-21
      },
      details25:{
        name:'健康磁性多功能床垫1张(1.5米)、负离子远红被一张、健康磁性枕2个',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-22
      },
      openTZ20074:false,
      detailsTZ200741:{
        name:'健康磁性多功能床垫',
        code:'CN-T009-01-00',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-21
      },
      detailsTZ200742:{
        name:'健康磁性多功能床垫(小)',
        code:'CN-T007-01-00',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-22
      },
      //0508纳米微雕3.0套组
      open2:false,
      timer:'',
      details26:{
        name:'魅力红围巾',
        code:'QC(CN)01024',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-23
      },
      details27:{
        name:'天空蓝围巾',
        code:'CN-T015-01-30',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-24
      },
      details28:{
        name:'高级灰围巾',
        code:'CN-T015-01-40',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-25
      },
      //03025养生仪
      open5:false,
      open030251:false,
      open030252:false,
      radio4:'0',
      details33:{
        name:'A套餐',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-199
      },
      details34:{
        name:'B套餐',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-200
      },
      details35:{
        name:'C套餐',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-32
      },
      //TZ(CN)20033
      TZ20033:false,
      details36:{
        name:'能量围巾（高级灰）',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-58
      },
      details37:{
        name:'能量围巾（活力橙）',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-59
      },
      details38:{
        name:'能量围巾（天空蓝）',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-60
      },
      details39:{
        name:'能量围巾（魅力红）',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-61
      },
      TZCN20075:false,
      TZCN20075list:[{
        name:'臻颜凝萃滋润套装',
        code:'HZ(CN)01052',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-62
      },{
        name:'臻颜凝萃平衡套装',
        code:'HZ(CN)01053',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-63
      },
      ],
      TZCN20076:false,
      TZCN20076list:[
        {
          name:'臻颜凝萃滋润套装',
          code:'HZ(CN)01052',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-62
        },{
          name:'臻颜凝萃平衡套装',
          code:'HZ(CN)01053',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-63
        },
      ],
      rules: {
        memberNo: [{ required: true, message: '请填写订货人编号', trigger: 'change' }],
        password: [{ required: true, message: '请填写您的支付密码', trigger: 'change' }]
      },
      goods: [],
      goodsLoading: false,
      orderNo: undefined
    }
  },
  watch:{
    'order.memberNo': function(val) {
      this.order.memberNo =  val.toUpperCase()
    },
    zengPin: {
      handler(val) {
        console.log(val, 'zengPin')
      },
      deep: true,
      immediate: true
    },
      goods:{
        handler() {
          for(let i=0;i<this.goods.length;i++){
            for(let j=0;j<this.goods[i].children.length;j++){
              // CN-N000102-24
              if( this.goods[i].children[j].id === 14574){
                this.CNN00010224_num = this.goods[i].children[j].quantity
              }
              // CN-M006-01-20
              if(this.goods[i].children[j].id === 13193){
                this.CNM0060120num = this.goods[i].children[j].quantity
                if(this.CNM0060120num === 0){
                  this.CNM0060120id = ''
                }
              }
              // CN-N000012-24
              if(this.goods[i].children[j].id === 13896){
                this.CNN00001224num = this.goods[i].children[j].quantity
                if(this.CNN00001224num === 0){
                  this.CNN00001224id = ''
                }
              }
              // CN-N000109-24
              if(this.goods[i].children[j].id === 14613){
                this.CNN00010924num = this.goods[i].children[j].quantity
                if(this.CNN00010924num === 0){
                  this.CNN00010924id = ''
                }
              }

              // CN-N000110-24
              if(this.goods[i].children[j].id === 14614){
                this.CNN00011024num = this.goods[i].children[j].quantity
                if(this.CNN00011024num === 0){
                  this.CNN00011024_HX_list = []
                  this.CNN00011024_HY_list = []
                  this.CNN00011024_WJ_list = []
                  this.CNN00011024_HX_num = 0
                  this.CNN00011024_HY_num = 0
                  this.CNN00011024_WJ_num = 0
                }
              }
              // CN-N000111-24
              if(this.goods[i].children[j].id === 14615){
                this.CNN00011124num = this.goods[i].children[j].quantity
                if(this.CNN00011124num === 0){
                  this.CNN00011124_WJ_list = []
                  this.CNN00011124_WJ_num = 0
                }
              }

              // CN-N000117-24
              if(this.goods[i].children[j].id=== 14713){
                this.CNN00011724num = this.goods[i].children[j].quantity
                if(this.CNN00011724num === 0){
                  this.CNN00011724_CD_id = ''
                }
              }
              // CN-N000118-24
              if(this.goods[i].children[j].id === 14714){
                this.CNN00011824num = this.goods[i].children[j].quantity
                if(this.CNN00011824num === 0){
                  this.CNN00011824_HX_list = []
                  this.CNN00011824_HY_list = []
                  this.CNN00011824_HX_num = 0
                  this.CNN00011824_HY_num = 0
                }
              }

              //  id 14113 TZ(CN)19511
              //  id 14114 CN-N000024-24
              if(this.goods[i].children[j].id === 14113  && this.goods[i].children[j].quantity>0){
                this.Mattress_num = this.goods[i].children[j].quantity
              }
              if(this.goods[i].children[j].id === 14113  && this.goods[i].children[j].quantity === 0){
                this.Mattress_num = this.goods[i].children[j].quantity
              }
              if(this.goods[i].children[j].id === 14114  && this.goods[i].children[j].quantity>0){
                this.Mattress_num1 = this.goods[i].children[j].quantity
              }
              if(this.goods[i].children[j].id === 14114  && this.goods[i].children[j].quantity === 0){
                this.Mattress_num1 = this.goods[i].children[j].quantity
              }
              //  id 14153 PK02624
              if((this.goods[i].children[j].id === 14153 ) && this.goods[i].children[j].quantity>0){
                this.PK02624_display  = true
                this.PK02624_num = this.goods[i].children[j].quantity
              }
              if(this.goods[i].children[j].id === 14153 && this.goods[i].children[j].quantity === 0){
                this.PK02624_display  = false
                this.PK02624_num = this.goods[i].children[j].quantity
              }
              // CN-N000019-24
              if(this.goods[i].children[j].id === 14076 && this.goods[i].children[j].quantity>0){
                this.TZCN19508_display  = true
                this.TZCN19508_num = this.goods[i].children[j].quantity
              }
              if(this.goods[i].children[j].id === 14076 && this.goods[i].children[j].quantity === 0){
                this.TZCN19508_display  = false
                this.TZCN19508_num = this.goods[i].children[j].quantity
              }
              // CN-N000039-24
              if(this.goods[i].children[j].id === 14233 && this.goods[i].children[j].quantity>0){
                this.CN24_display  = true
                this.CN24_num = this.goods[i].children[j].quantity
              }
              if(this.goods[i].children[j].id === 14233 && this.goods[i].children[j].quantity === 0){
                this.CN24_display  = false
                this.CN24_num = this.goods[i].children[j].quantity
              }
              //女神套组
              if(this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity>0 && this.goods[i].children[j].name === '女神套装'){
                this.open = true
              }
              if(this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity===0 && this.goods[i].children[j].name === '女神套装'){
                this.open = false
                this.details1.quantity = 0
                this.details2.quantity = 0
                this.details3.quantity = 0
                this.details4.quantity = 0
                this.details5.quantity = 0
                this.details6.quantity = 0
                this.details7.quantity = 0
                this.details8.quantity = 0
                this.details9.quantity = 0
                this.details10.quantity = 0
                this.details11.quantity = 0
                this.details12.quantity = 0
                this.details13.quantity = 0
              }
              //康丽舒套组
              if(this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity>0 && this.goods[i].children[j].name === '康丽舒络套组'){
                this.opens = true
              }
              if(this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity===0 && this.goods[i].children[j].name === '康丽舒络套组'){
                this.opens = false
                this.details14.quantity = 0
                this.details15.quantity = 0
                this.details16.quantity = 0
                this.details17.quantity = 0
              }
              //养生仪 1618975066000  1657036799000
              if (this.goods[i].children[j].id === 10069 && this.goods[i].children[j].quantity > 0  && 1618975066000 <this.nowTime ){
                this.openss = true
              }
              if(this.goods[i].children[j].id === 10869 && this.goods[i].children[j].quantity > 0  && 1618975066000 <this.nowTime ){
                this.opensss = true
              }
              if(this.goods[i].children[j].quantity === 0 && this.goods[i].children[j].id === 10069 && 1618975066000 <this.nowTime ){
                this.openss = false
                this.radio1 = '0'
                this.details18.quantity = 0
                this.details19.quantity = 0
                this.details20.quantity = 0
              }
              if(this.goods[i].children[j].id === 10869 && this.goods[i].children[j].quantity === 0  && 1618975066000 <this.nowTime ){
                this.opensss = false
                this.radio = '0'
                this.details21.quantity = 0
                this.details22.quantity = 0
                this.details23.quantity = 0
              }
            //养生睡眠套组
              if(this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity > 0){
                this.open1 = true
              }
              if(this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity === 0){
                // this.openTZ20074 = false
                this.open1 = false
                this.detailsTZ200741.quantity = 0
                this.detailsTZ200742.quantity = 0
              }
              // CN-N000025-24
              if(this.goods[i].children[j].id === 13893 && this.goods[i].children[j].quantity > 0){
                this.openTZ20074 = true
              }
              if(this.goods[i].children[j].id === 13893 && this.goods[i].children[j].quantity === 0){
                this.openTZ20074 = false
                this.detailsTZ200741.quantity = 0
                this.detailsTZ200742.quantity = 0
              }
            //0508纳米微雕3.0套组
            //   if(this.goods[i].children[j].id === 12856 && this.goods[i].children[j].quantity > 0  && 1618975066000 <this.nowTime < 1622563200000){
            //     this.open2 = true
            //   }
            //   if(this.goods[i].children[j].quantity === 0 && this.goods[i].children[j].id === 12856 && 1618975066000 <this.nowTime < 1622563200000) {
            //     this.open2 = false
            //     this.details26.quantity = 0
            //     this.details27.quantity = 0
            //     this.details28.quantity = 0
            //   }

            // 03025养生仪
              if(1633449600000 < this.nowTime && this.nowTime < 1636127999000){
                this.open030251 = true
                this.open030252 = false
              }
              if(1633449600000 > this.nowTime || this.nowTime > 1636127999000){
                this.open030251 = false
                this.open030252 = true
              }
              if(this.goods[i].children[j].id === 12875 && this.goods[i].children[j].quantity > 0 && 1622563200000 <this.nowTime ){
                console.log(this.nowTime)
                this.open5 = true
              }
              if(this.goods[i].children[j].id === 12875 && this.goods[i].children[j].quantity === 0 && 1622563200000 <this.nowTime ){
                this.radio4 = '0'
                this.open5 = false
              }
            //  12993 TZ(CN)20033	合家欢健康套组
            //   1631635200000 <this.nowTime  && this.nowTime < 1633363200000
              if(this.goods[i].children[j].id === 12993 && this.goods[i].children[j].quantity > 0 && (1631635200000 <this.nowTime  < 1633363200000) ){
                this.TZ20033 = true
              }
              if(this.goods[i].children[j].id === 12993 && this.goods[i].children[j].quantity === 0 && (1631635200000 <this.nowTime  < 1633363200000) ){
                this.TZ20033 = false
                this.details36.quantity = 0
                this.details37.quantity = 0
                this.details38.quantity = 0
                this.details39.quantity = 0
              }
            //   TZ(CN)20075时光宝盒白金套组
              if(this.goods[i].children[j].id === 13913 && this.goods[i].children[j].quantity > 0){
                this.TZCN20075 = true
              }
              if(this.goods[i].children[j].id === 13913 && this.goods[i].children[j].quantity === 0){
                this.TZCN20075 = false
                this.TZCN20075list[0].quantity = 0
                this.TZCN20075list[1].quantity = 0
              }
              //   TZ(CN)20076时光宝盒白金套组
              if(this.goods[i].children[j].id === 13973 && this.goods[i].children[j].quantity > 0){
                this.TZCN20076 = true
              }
              if(this.goods[i].children[j].id === 13973 && this.goods[i].children[j].quantity === 0){
                this.TZCN20076 = false
                this.TZCN20076list[0].quantity = 0
                this.TZCN20076list[1].quantity = 0
              }
            }
          }
        },
        deep:true,
      }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'isAgent'
    ]),
    amount() {
      if (!this.order.details || !this.order.details.length) {
        return 0
      }
      return this.order.details.reduce((acc, cur) => {
        return cur.price * cur.quantity + acc
      }, 0).toFixed(2)
    },
    pv() {
      if (!this.order.details || !this.order.details.length) {
        return 0
      }
      return this.order.details.reduce((acc, cur) => {
        return cur.pv * cur.quantity + acc
      }, 0).toFixed(2)
    }
  },
  mounted() {
    if (!this.isAgent) {
      this.activeIndex = 1
      this.order.memberNo = this.userCode
      this.getGoodsData()
    }
    this.timer = setInterval(()=>{
      this.nowTime = new Date().getTime()
    },1000)
    this.$once('hook:beforeDestroy', ()=>{
      clearInterval(this.timer)
    })
  },
  created() {
    this.getList()
    this.getfcBalance()
    // this.getTime()
    this.getYSYTime()
  },
  methods: {
    getList(){
      this.order.memberNo = this.$route.query.results
    },
    onBack() {
      this.$router.go(-1)
    },
    onNext() {
      this.order.details = []
      this.$refs['orderForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          validateMember({ memberNo: this.order.memberNo }).then(() => {
            this.activeIndex = 1
            this.loading = false
            this.getGoodsData()
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    handleChange() {
    },
    getGoodsData() {
      if(!this.isAgent){
        this.goodsLoading = true
        fetchGoods().then(res => {
          this.goods = res.data
          this.goodsLoading = false
        }).catch(err => {
          console.warn(err)
          this.goodsLoading = false
        })
      }else{
        this.goodsLoading = true
        goodsAgent({'userCode':this.order.memberNo}).then(res => {
          this.goods = res.data
          this.goodsLoading = false
        }).catch(err => {
          console.warn(err)
          this.goodsLoading = false
        })
      }
    },
    onForward() {
      this.activeIndex = 0
      this.$router.go(0)
    },
    hyTypeChange(e){
      this.HuYao_19508_list= []
      this.HuYao_19508_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.HuYao_19508_list.push(e)
          this.HuYao_19508_num += e.quantity
        }
      })
    },
    hxTypeChange(e) {
      this.HuXi_19508_list = []
      this.HuXi_19508_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.HuXi_19508_list.push(e)
          this.HuXi_19508_num += e.quantity
        }
      })
    },
    CDChange(e) {
      this.Mattress_list = []
      this.Mattress_size_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.Mattress_list.push(e)
          this.Mattress_size_num += e.quantity
        }
      })
    },
    CDChange1(e) {
      console.log(e)
      this.Mattress_list1 = []
      this.Mattress_size_num1 = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.Mattress_list1.push(e)
          this.Mattress_size_num1 += e.quantity
        }
      })
    },
    CN_hxTypeChange(e){
      this.CN24_HX_list = []
      this.CN24_HX_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.CN24_HX_list.push(e)
          this.CN24_HX_num += e.quantity
        }
      })
    },
    PK_HX_Change(e){
      this.PK02624_HX_list = []
      this.PK02624_HX_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.PK02624_HX_list.push(e)
          this.PK02624_HX_num += e.quantity
        }
      })
    },
    PK_HY_Change(e){
      this.PK02624_HY_list = []
      this.PK02624_HY_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.PK02624_HY_list.push(e)
          this.PK02624_HY_num += e.quantity
        }
      })
    },
    PK_JY_Change(e){
      this.PK02624_JY_list = []
      this.PK02624_JY_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.PK02624_JY_list.push(e)
          this.PK02624_JY_num += e.quantity
        }
      })
    },
    PK_DD_Change(e){
      this.PK02624_DD_list = []
      this.PK02624_DD_num = 0
      e.forEach(e=>{
        if(e.quantity>0){
          this.PK02624_DD_list.push(e)
          this.PK02624_DD_num += e.quantity
        }
      })
    },
    getYSYidChange(e){
      this.CNM0060120id = e
    },
    getKFYChange(e){
      this.CNN00001224id = e
    },
    get_CNN00010924_Change(e){
      this.CNN00010924id = e
    },
    CNN00010224_HX_Change(e){
      this.CNN00010224_HX_list = e
    },
    CNN00010224_HY_Change(e){
      this.CNN00010224_HY_list = e
    },
    // CN-N000110-24
    CNN00011024_HX_Change(e){
      this.CNN00011024_HX_num = 0
      this.CNN00011024_HX_list = e
      this.CNN00011024_HX_list.forEach(v=>{
        this.CNN00011024_HX_num += v.quantity
      })

    },
    CNN00011024_HY_Change(e){
      this.CNN00011024_HY_num = 0
      this.CNN00011024_HY_list = e
      this.CNN00011024_HY_list.forEach(v=>{
        this.CNN00011024_HY_num += v.quantity
      })
    },
    CNN00011024_WJ_Change(e){
      this.CNN00011024_WJ_num = 0
      this.CNN00011024_WJ_list = e
      this.CNN00011024_WJ_list.forEach(v=>{
        this.CNN00011024_WJ_num += v.quantity
      })
    },
    //  CN-N000111-24
    CNN00011124_WJ_Change(e){
      this.CNN00011124_WJ_num = 0
      this.CNN00011124_WJ_list = e
      this.CNN00011124_WJ_list.forEach(v=>{
        this.CNN00011124_WJ_num += v.quantity
      })
    },
    //  CN-N000117-24
    CNN00011724_CD_Change(e){
      this.CNN00011724_CD_id = e
    },
    // CN-N000118-24
    CNN00011824_HX_Change(e){
      this.CNN00011824_HX_num = 0
      this.CNN00011824_HX_list = e
      this.CNN00011824_HX_list.forEach(v=>{
        this.CNN00011824_HX_num += v.quantity
        console.log(this.CNN00011824_HX_num)
      })

    },
    CNN00011824_HY_Change(e){
      this.CNN00011824_HY_num = 0
      this.CNN00011824_HY_list = e
      this.CNN00011824_HY_list.forEach(v=>{
        this.CNN00011824_HY_num += v.quantity
      })
    },
    onConfirm() {

      if (this.zengPin) {
        if (this.zengPin.isAttend) {
          if (!this.zengPin.numIsSame) {
            this.$message.error('赠品数量不符')
            return
          }
          this.order.details = this.order.details.concat(this.zengPin.options)
        }
      }
      console.log(this.order.details, 'this.order.details')
      if(this.CNN00010224_num > 0){
        let num1 = 0
        let num2 = 0
        this.CNN00010224_HX_list.forEach(v=>{
          num1 += v.quantity
        })
        this.CNN00010224_HY_list.forEach(v=>{
          num2 += v.quantity
        })
        if(this.CNN00010224_num * 3 === num1 && this.CNN00010224_num * 3 === num2 ){
          this.CNN00010224_HX_list.forEach(v=>{
            if(v.quantity > 0){
              this.order.details.push(v)
            }
          })
          this.CNN00010224_HY_list.forEach(v=>{
            if(v.quantity > 0){
              this.order.details.push(v)
            }
          })
        }else{
          return this.$message.error('购买套组所选产品数量不符')
        }
      }

      if(this.CNM0060120num >= 1 && this.CNM0060120id === '' && this.CNM0060120time){
        return this.$message.error('所选尺码数量与所选产品数量不符')
      }
      else if(this.CNM0060120num >= 1 && this.CNM0060120id != '' && this.CNM0060120time){
        let id = {
          id:'',
          code:'',
          name:'',
          price:0,
          pv:0,
          quantity:1,
          gift:'是'
        }
        switch (this.CNM0060120id){
          case -150:
            id.name = '赠品1'
            break;
          case -151:
            id.name = '赠品2'
            break;
          case -152:
            id.name = '赠品3'
            break;
          case -154:
            id.name = '赠品4'
            break;
          case -155:
            id.name = '赠品5'
            break;
          case -153:
            id.name = '赠品6'
            break;
          default:
            id.name = ''
        }
        id.id = this.CNM0060120id
        this.order.details.push(id)
      }
      if(this.CNN00001224num >= 1 && this.CNN00001224id === '' ){
        return this.$message.error('所选尺码数量与所选产品数量不符')
      }
      else if(this.CNN00001224num >= 1 && this.CNN00001224id != '' ){
        let id = {
          id:'',
          code:'',
          name:'',
          price:0,
          pv:0,
          quantity:1,
          gift:'是'
        }
        switch (this.CNN00001224id){
          case -22:
            id.name = '健康磁性多功能床垫（小）'
            id.code = 'CN-T008-01-00'
            break;
          case -21:
            id.name = '健康磁性多功能床垫'
            id.code = 'CN-T009-01-00'
            break;
          default:
            id.name = ''
        }
        id.id = this.CNN00001224id
        this.order.details.push(id)
      }
      // CN-N000109-24
      if(this.CNN00010924num >= 1 && this.CNN00010924id === '' ){
        return this.$message.error('所选尺码数量与所选产品数量不符')
      }
      else if(this.CNN00010924num >= 1 && this.CNN00010924id != '' ){
        let id = {
          id:'',
          code:'',
          name:'',
          price:0,
          pv:0,
          quantity:1,
          gift:'是'
        }
        switch (this.CNN00010924id){
          case -22:
            id.name = '健康磁性多功能床垫（小）'
            id.code = 'CN-T008-01-00'
            break;
          case -21:
            id.name = '健康磁性多功能床垫'
            id.code = 'CN-T009-01-00'
            break;
          default:
            id.name = ''
        }
        id.id = this.CNN00010924id
        this.order.details.push(id)
      }
      // CN-N000110-24
      if(this.CNN00011024num > 0 && ((this.CNN00011024_HX_num !== this.CNN00011024num) || (this.CNN00011024_HY_num !== this.CNN00011024num) || (this.CNN00011024_WJ_num !== this.CNN00011024num)) ){
        return this.$message.error('所选尺码数量与所选产品数量不符')
      }
      else{
        this.CNN00011024_HX_list.forEach(v=>{
          this.order.details.push(v)
        })
        this.CNN00011024_HY_list.forEach(v=>{
          this.order.details.push(v)
        })
        this.CNN00011024_WJ_list.forEach(v=>{
          this.order.details.push(v)
        })
      }
      // CN-N000111-24
      if(this.CNN00011124num > 0 && (this.CNN00011124_WJ_num !== this.CNN00011124num) ){
        return this.$message.error('所选尺码数量与所选产品数量不符')
      }
      else{
        this.CNN00011124_WJ_list.forEach(v=>{
          this.order.details.push(v)
        })
      }
      // CN-N000117-24
      if(this.CNN00011724num >= 1 && this.CNN00011724_CD_id === '' ){
        return this.$message.error('所选尺码数量与所选产品数量不符')
      }
      else if(this.CNN00011724num >= 1 && this.CNN00011724_CD_id != '' ) {
        let id = {
          id: '',
          code: '',
          name: '',
          price: 0,
          pv: 0,
          quantity: 1,
          gift: '是'
        }
        switch (this.CNN00011724_CD_id) {
          case -1018:
            id.name = '健康磁性多功能床垫（小）'
            id.code = 'CN-T008-01-00'
            break;
          case -1019:
            id.name = '健康磁性多功能床垫'
            id.code = 'CN-T009-01-00'
            break;
          default:
            id.name = ''
        }
        id.id = this.CNN00011724_CD_id
        this.order.details.push(id)
      }
      // CN-N000118-24
      if(this.CNN00011824num > 0 && ((this.CNN00011824_HX_num !== this.CNN00011824num * 3 ) || (this.CNN00011824_HY_num !== this.CNN00011824num * 3)) ){
        return this.$message.error('所选尺码数量与所选产品数量不符')
      }
      else{
        this.CNN00011824_HX_list.forEach(v=>{
          this.order.details.push(v)
        })
        this.CNN00011824_HY_list.forEach(v=>{
          this.order.details.push(v)
        })
      }

      if(this.TZCN19508_num > 0){
        if(this.TZCN19508_num !== this.HuXi_19508_num || this.TZCN19508_num !== this.HuYao_19508_num){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.HuYao_19508_list.forEach(v=>{
            this.order.details.push(v)
          })
          this.HuXi_19508_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
      }
      if(this.Mattress_num > 0){
        if(this.Mattress_num !== this.Mattress_size_num ){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.Mattress_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
      }
      if(this.Mattress_num1 > 0){
        if(this.Mattress_num1 !== this.Mattress_size_num1 ){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.Mattress_list1.forEach(v=>{
            this.order.details.push(v)
          })
        }
      }
      if(this.PK02624_num > 0){
        if(this.PK02624_num*6 !== this.PK02624_HX_num){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.PK02624_HX_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
        if((this.PK02624_num*6 !== this.PK02624_HY_num)){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.PK02624_HY_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
        if((this.PK02624_num*3 !== this.PK02624_JY_num)){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.PK02624_JY_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
        if((this.PK02624_num*3 !== this.PK02624_DD_num)){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.PK02624_DD_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
      }
      if(this.CN24_num > 0){
        if(this.CN24_num*2 !== this.CN24_HX_num){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.CN24_HX_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
        if((this.PK02624_num*6 !== this.PK02624_HY_num)){
          return this.$message.error('所选尺码数量与所选产品数量不符')
        }else{
          this.PK02624_HY_list.forEach(v=>{
            this.order.details.push(v)
          })
        }
      }
      for(let i=0;i<this.goods.length;i++) {
        for (let j = 0; j < this.goods[i].children.length; j++) {
          //女神套组
          if (this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '女神套装') {
            this.ids = this.goods[i].children[j].quantity
            let ids1 = this.details1.quantity*1 + this.details2.quantity*1+this.details3.quantity*1+
                this.details4.quantity*1+this.details5.quantity*1+this.details6.quantity*1+this.details7.quantity*1+this.details8.quantity*1
            let ids2 = this.details9.quantity*1 + this.details10.quantity*1+this.details11.quantity*1+
                this.details12.quantity*1+this.details13.quantity*1
            if(this.ids != ids1 ){
              return  this.$message.error('旋磁智感健康文胸产品数量应与女神套装一致');
            }if(this.ids != ids2){
              return  this.$message.error('远红外塑体衣产品数量应与女神套装一致');
            }
          }
          //康丽舒
          if (this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '康丽舒络套组') {
            this.ids1 = this.goods[i].children[j].quantity
            let ids3 = this.details14.quantity*1 + this.details15.quantity*1+this.details16.quantity*1+ this.details17.quantity*1
            if(this.ids1 != ids3 ){
              return  this.$message.error('远健康磁性护腰产品数量应与康丽舒络套组一致');
            }
          }
          //养生仪

          if(this.goods[i].children[j].id === 10069 && this.goods[i].children[j].quantity > 0){
            if(this.radio1 === '0'){
              return  this.$message.error('请选择套餐')
            }
            // if(this.details18.quantity*1+this.details19.quantity*1+this.details20.quantity*1 < 1){
            //   return  this.$message.error('请选择套餐')
            // }
            // if(this.details18.quantity*1+this.details19.quantity*1+this.details20.quantity*1 != this.goods[i].children[j].quantity){
            //   return  this.$message.error('套餐数量应与购买数量一致')
            // }
          }
          if(this.goods[i].children[j].id === 10869 && this.goods[i].children[j].quantity > 0){
            if(this.radio === '0'){
              return  this.$message.error('请选择套餐')
            }
            // if(this.details21.quantity*1+this.details22.quantity*1+this.details23.quantity*1 < 1){
            //   return  this.$message.error('请选择套餐')
            // }
            // if(this.details21.quantity*1+this.details22.quantity*1+this.details23.quantity*1 != this.goods[i].children[j].quantity){
            //   return  this.$message.error('套餐数量应与购买数量一致')
            // }
          }

        //  睡眠套组
          if(this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity > 0){
            if(this.details24.quantity*1 + this.details25.quantity*1 < 1){
              return  this.$message.error('请选择套餐')
            }
            if(this.details24.quantity*1 + this.details25.quantity*1 != this.goods[i].children[j].quantity){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
          }
          // CN-N000025-24
          if(this.goods[i].children[j].id === 13893 && this.goods[i].children[j].quantity > 0){
            if(this.detailsTZ200741.quantity*1 + this.detailsTZ200742.quantity*1 < 1){
              return  this.$message.error('请选择套餐')
            }
            if(this.detailsTZ200741.quantity*1 + this.detailsTZ200742.quantity*1 != this.goods[i].children[j].quantity){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
          }
          // 0508纳米微雕3.0套组
          // if(this.goods[i].children[j].id === 12856 && this.goods[i].children[j].quantity > 0){
          //   if(this.details26.quantity*1 + this.details27.quantity*1 +this.details28.quantity*1 < 1){
          //     return  this.$message.error('请选择套餐')
          //   }
          //   if(this.details26.quantity*1 + this.details27.quantity*1 +this.details28.quantity*1 != this.goods[i].children[j].quantity){
          //     return  this.$message.error('套餐数量应与购买数量一致')
          //   }
          // }

        //03025养生仪
          if(this.goods[i].children[j].id === 12875 && this.goods[i].children[j].quantity > 0 && 1622563200000 <this.nowTime ){
            if(this.radio4 === '0'){
              return  this.$message.error('请选择套餐')
            }
            //  03025养生仪
            if (this.radio4 === '1'){
              this.details33.quantity = this.goods[i].children[j].quantity
              this.details34.quantity = 0
              this.details35.quantity = 0
              this.order.details.push(this.details33)
            }
            if (this.radio4 === '2'){
              this.details33.quantity = 0
              this.details34.quantity = this.goods[i].children[j].quantity
              this.details35.quantity = 0
              this.order.details.push(this.details34)
            }
            if (this.radio4 === '3'){
              this.details33.quantity = 0
              this.details34.quantity = 0
              this.details35.quantity = this.goods[i].children[j].quantity
              this.order.details.push(this.details35)
            }
          }

        //  12993 TZ(CN)20033	合家欢健康套组


          if(this.goods[i].children[j].id === 12993 && this.goods[i].children[j].quantity > 0){
            if(this.details36.quantity >0){
              this.order.details.push(this.details36)
            }
            if(this.details37.quantity >0){
              this.order.details.push(this.details37)
            }
            if(this.details38.quantity >0){
              this.order.details.push(this.details38)
            }
            if(this.details39.quantity >0){
              this.order.details.push(this.details39)
            }
            if(this.goods[i].children[j].quantity != (this.details36.quantity+this.details37.quantity+this.details38.quantity+this.details39.quantity)){
              return  this.$message('套餐数量应与购买数量一致')
            }
          }

        //   TZ(CN)20075时光宝盒白金套组
          if(this.goods[i].children[j].id === 13913 && this.goods[i].children[j].quantity > 0){
            if(this.goods[i].children[j].quantity != this.TZCN20075list[0].quantity + this.TZCN20075list[1].quantity){
              return  this.$message('套餐数量应与购买数量一致')
            }
            if(this.TZCN20075list[0].quantity>0){
              this.order.details.push(this.TZCN20075list[0])
            }
            if(this.TZCN20075list[1].quantity>0){
              this.order.details.push(this.TZCN20075list[1])
            }
          }
          //   TZ(CN)20076时光宝盒白金套组
          if(this.goods[i].children[j].id === 13973 && this.goods[i].children[j].quantity > 0){
            if(this.goods[i].children[j].quantity != this.TZCN20076list[0].quantity + this.TZCN20076list[1].quantity){
              return  this.$message('套餐数量应与购买数量一致')
            }
            if(this.TZCN20076list[0].quantity>0){
              this.order.details.push(this.TZCN20076list[0])
            }
            if(this.TZCN20076list[1].quantity>0){
              this.order.details.push(this.TZCN20076list[1])
            }
          }
        }


      }

      //女神
      if(this.details1.quantity>0){
        this.order.details.push(this.details1)
      }
      if(this.details2.quantity>0){
        this.order.details.push(this.details2)
      }
      if(this.details3.quantity>0){
        this.order.details.push(this.details3)
      }
      if(this.details4.quantity>0){
        this.order.details.push(this.details4)
      }
      if(this.details5.quantity>0){
        this.order.details.push(this.details5)
      }
      if(this.details6.quantity>0){
        this.order.details.push(this.details6)
      }
      if(this.details7.quantity>0){
        this.order.details.push(this.details7)
      }
      if(this.details8.quantity>0){
        this.order.details.push(this.details8)
      }
      if(this.details9.quantity>0){
        this.order.details.push(this.details9)
      }
      if(this.details10.quantity>0){
        this.order.details.push(this.details10)
      }
      if(this.details11.quantity>0){
        this.order.details.push(this.details11)
      }
      if(this.details12.quantity>0){
        this.order.details.push(this.details12)
      }
      if(this.details13.quantity>0){
        this.order.details.push(this.details13)
      }
      //康丽舒络套组
      if(this.details14.quantity>0){
        this.order.details.push(this.details14)
      }
      if(this.details15.quantity>0){
        this.order.details.push(this.details15)
      }
      if(this.details16.quantity>0){
        this.order.details.push(this.details16)
      }
      if(this.details17.quantity>0){
        this.order.details.push(this.details17)
      }
      //养生仪
      if(this.radio1 === '1'){
        this.details18.quantity = 1
        this.details19.quantity = 0
        this.details20.quantity = 0
        this.order.details.push(this.details18)
      }
      if(this.radio1 === '2'){
        this.details19.quantity = 1
        this.details18.quantity = 0
        this.details20.quantity = 0
        this.order.details.push(this.details19)
      }
      if(this.radio1 === '3'){
        this.details20.quantity = 1
        this.details18.quantity = 0
        this.details19.quantity = 0
        this.order.details.push(this.details20)
      }
      // if(this.details18.quantity>0){
      //   this.order.details.push(this.details18)
      // }
      // if(this.details19.quantity>0){
      //   this.order.details.push(this.details19)
      // }
      // if(this.details20.quantity>0){
      //   this.order.details.push(this.details20)
      // }
      if(this.radio === '1'){
        this.details21.quantity = 1
        this.details22.quantity = 0
        this.details23.quantity = 0
        this.order.details.push(this.details21)
      }
      if(this.radio === '2'){
        this.details22.quantity = 1
        this.details21.quantity = 0
        this.details23.quantity = 0
        this.order.details.push(this.details22)
      }
      if(this.radio === '3'){
        this.details23.quantity = 1
        this.details21.quantity = 0
        this.details22.quantity = 0
        this.order.details.push(this.details23)
      }
      // if(this.details21.quantity>0){
      //   this.order.details.push(this.details21)
      // }
      // if(this.details22.quantity>0){
      //   this.order.details.push(this.details22)
      // }
      // if(this.details23.quantity>0){
      //   this.order.details.push(this.details23)
      // }
      //养生睡眠
      if(this.details24.quantity>0){
        this.order.details.push(this.details24)
      }
      if(this.details25.quantity>0){
        this.order.details.push(this.details25)
      }
      // CN-N000025-24
      if(this.detailsTZ200741.quantity>0){
        this.order.details.push(this.detailsTZ200741)
      }
      if(this.detailsTZ200742.quantity>0){
        this.order.details.push(this.detailsTZ200742)
      }
      //
      // if(this.details26.quantity>0){
      //   this.order.details.push(this.details26)
      // }
      // if(this.details27.quantity>0){
      //   this.order.details.push(this.details27)
      // }
      // if(this.details28.quantity>0){
      //   this.order.details.push(this.details28)
      // }

      //去重
      for (let b=0;b<this.order.details.length;b++){
        for(let c=b+1;c<this.order.details.length;c++){
          if(this.order.details[b] === this.order.details[c]){
            this.order.details.splice(c,1)
            c--
          }
          if(this.order.details[b].quantity === '0' ||this.order.details[b].quantity === null || this.order.details[b].quantity === undefined || this.order.details[b].quantity === '' ||this.order.details[b].quantity === 0 ){
            this.order.details.slice(b,1)
          }
        }
      }
      for(let v =0;v<this.order.details.length;v++){
        if(this.order.details[v].quantity === 0){
          this.order.details.splice(v,1)
          v--
        }
      }
      if (!this.order.details || !this.order.details.length) {
        this.$message.error('至少要选择一个商品')
      }
      else {
        this.activeIndex = 2
        // isPaymentPassword().then(res => {
        //   if (res.data) {
        //     this.$router.push({path: '/two-password'})
        //   } else {
        //     this.activeIndex = 2
        //   }
        // }).catch(err => {
        //   console.error(err)
        // })
      }

    },
    onForward2() {
      this.$router.go(0)
      this.activeIndex = 1
      for(let i=0;i<this.order.details.length;i++){
        if(this.order.details[i].id === -150 ||this.order.details[i].id === -151 || this.order.details[i].id === -152 || this.order.details[i].id === -153 || this.order.details[i].id === -154 || this.order.details[i].id === -155){
          this.order.details.splice(i,1)
          i--
        }
        if(this.order.details[i].id === -22 ||this.order.details[i].id === -21){
          this.order.details.splice(i,1)
          i--
        }
      }
    },
    getYSYTime(){
      CNAugustPromotionios().then(res=>{
        this.CNM0060120time = res.data
      })
    },
    getTime(){
      this.nowTime = new Date().getTime()
    },
    onSubmit() {
      this.$refs['orderForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          let details = this.order.details.map(ele => {
            return {
              goodsId: ele.id,
              quantity: ele.quantity,
              goodsCode: ele.code,
              price: ele.price,
              isH0orgood: ele.isH0orgood
            }
          })
          if (this.zengPin) {
            if (this.zengPin.isAttend) {
              details = this.order.details.map(ele => {
                return {
                  goodsId: ele.id,
                  quantity: ele.quantity,
                  goodsCode: ele.code,
                  price: ele.price,
                  isH0orgood: ele.isH0orgood,
                  isGive: ele.isGive
                }
              })
            }
          }
          createOrder({
            memberNo: this.order.memberNo,
            agentNo: this.isAgent ? this.userCode : '',
            orderType: 18,
            isFc:this.isFc,
            password: this.order.password,
            details
          }).then(res => {
            const { orderNo } = res.data
            this.orderNo = orderNo
            this.loading = false
            this.activeIndex = 3
            this.order.details = []
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    getfcBalance(){
      fcBalance().then(res=>{
        this.Fc = res.data
      })
    },
    onBackHome() {
      this.$router.push('/order/index')
    },
    onContinue() {
      location.reload()
      // for (let i=0;i<this.goods.length;i++){
      //   for (let j=0;j<this.goods[i].children.length;j++){
      //     this.goods[i].children[j].quantity = 0
      //   }
      // }
      // this.order = {
      //   memberNo: undefined,
      //   password: undefined,
      //   details: []
      // }
      // if (this.isAgent) {
      //   this.activeIndex = 0
      // } else {
      //   this.activeIndex = 1
      //   this.goods = []
      //   this.getGoodsData()
      // }
      // this.$nextTick(() => {
      //   this.$refs['orderForm'].resetFields()
      // })
    },
    orderDetailSummaries(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 5) {
          sums[index] = this.amount
        } else if (index === 6) {
          sums[index] = this.pv
        }
      })
      return sums
    },
    handleNoCheck(val) {
      if (val) {
        searchMember({ userCode: val.trim().toUpperCase() }).then(res => {
          if (res.data.length > 0) {
            this.memberNo = res.data[0].userName
          } else  {
            this.memberNo = '请输入正确的订货人编号'
          }
        })
      } else {
        this.memberNo = ''
      }
    }
  }
}
</script>

<style lang="scss">
.order-wrapper {
  .step2 {
    .summary {
      padding: 10px;
      font-size: 15px;
      font-weight: bold;
      span {
        padding-right: 20px;
        em {
          padding-left: 10px;
          font-size: 16px;
          font-style: normal;
          color: #FF0000;
        }
      }
    }
  }
  .step3 {
    .amt {
      color: #008800;
      font-weight: bold;
    }
  }
  .step4 {
    .success {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        max-height: 120px;
      }
      .success-txt {
        display: inline-block;
        padding: 10px 0;
        font-size: 14px;
        font-weight: bold;
        width: 420px;
        span {
          color: #FF0000;
          font-size: 16px;
        }
      }
      .el-button {
        margin-top: 20px;
      }
    }
  }
  .redspan {
    color: red;
  }
}
</style>
